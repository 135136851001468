import React, { useState, useEffect, useRef, useCallback } from "react"
import styled from "styled-components"
import theme from "@styles/theme"
import media from "@styles/media"
import { Sticky } from "@components/common/article"
import PropTypes from "prop-types"
import {
  motion,
  useMotionTemplate,
  useScroll,
  useTransform,
} from "framer-motion"

const Sidebar = styled.nav`
  overflow: hidden;
  flex-basis: 200px;
  flex-grow: 1;
  min-width: 200px;
  max-height: 400px;
  height: min-content;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  ul > li {
    position: relative;
    display: block;
    margin: 0;
  }
  ${media.md`
    display: none;
  `}
`

const StyledLink = styled.a`
  text-decoration: none;
  color: ${props =>
    props.active ? theme.color.blue400 : theme.color.blue600};
  font-weight: ${props => (props.active ? 700 : 500)};
  display: block;
  position: relative;
  padding: ${props => (props.active ? "0.5rem 0" : null)};
  transition: all 0.2s ease-in-out;
  &:hover,
  &:focus {
    text-decoration: none;
    color: ${props => props.simplified && theme.color.blue500};
  }
`

const useHeadingsData = () => {
  const [nestedHeadings, setNestedHeadings] = useState([])

  useEffect(() => {
    const headingElements = Array.from(document.querySelectorAll("h2"))
    const newNestedHeadings = getNestedHeadings(headingElements)
    setNestedHeadings(newNestedHeadings)
  }, [])

  return { nestedHeadings }
}

const getNestedHeadings = headingElements => {
  const nestedHeadings = []
  var index = 0

  headingElements.forEach(heading => {
    const { innerText: title, id } = heading
    // Change the id of the heading to be the index of the heading
    var navid = id !== "" ? id : "sidenav-" + index
    heading.id = navid
    nestedHeadings.push({ id: navid, title })
    index++
  })

  return nestedHeadings
}

const useIntersectionObserver = setActiveId => {
  useEffect(() => {
    const headingElements = Array.from(document.querySelectorAll("h2"))
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setActiveId(entry.target.id)
          }
        })
      },
      { root: null, rootMargin: "0px 0px -60% 0px", threshold: 1 }
    )

    headingElements.forEach(element => observer.observe(element))

    return () => observer.disconnect()
  }, [setActiveId])
}

export const SideBar = () => {
  const { scrollYProgress } = useScroll()
  const [activeId, setActiveId] = useState()
  const { nestedHeadings } = useHeadingsData()
  useIntersectionObserver(setActiveId)

  const refs = useRef([])
  const sidebarRef = useRef()

  const progress = useTransform(scrollYProgress, [0.1, 0.9], [0, 100])
  const fade = useMotionTemplate`linear-gradient(to bottom, rgba(255, 255, 255, 0.25), white ${progress}%, rgba(255, 255, 255, 0.25))`

  const handleClick = useCallback((event, id) => {
    event.preventDefault()
    const element = document.getElementById(id)
    if (element) {
      const top = element.getBoundingClientRect().top + window.scrollY - 96
      window.scrollTo({ top, behavior: "smooth" })
    }
  }, [])

  useEffect(() => {
    if (!activeId) return
    const item = refs.current[activeId]
    const sidebar = sidebarRef.current
    const itemHeight = item.getBoundingClientRect().height
    const top = item.offsetTop - sidebar.offsetTop - 4 * itemHeight
    sidebar.scrollTo({ top, behavior: "smooth" })
  }, [activeId, refs, sidebarRef])

  return (
    <div>
      <Sticky>
        <motion.div
          style={{
            maskImage: fade,
          }}
        >
          <Sidebar ref={sidebarRef}>
            <ul>
              {nestedHeadings.map(link => {
                return (
                  <li key={link.id} ref={el => (refs.current[link.id] = el)}>
                    <StyledLink
                      href={`#${link.id}`}
                      active={activeId === link.id}
                      onClick={event => handleClick(event, link.id)}
                    >
                      {link.title}
                    </StyledLink>
                  </li>
                )
              })}
            </ul>
          </Sidebar>
        </motion.div>
      </Sticky>
    </div>
  )
}

SideBar.propTypes = {
  cta: PropTypes.bool,
  message: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  simplified: PropTypes.bool,
}
