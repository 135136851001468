import React from "react"
import Layout from "@components/layout"
import { WhatsNew } from "@components/pages/whats-new"

const WhatsNewPage = () => {
  return (
    <Layout
      seo={{
        title: "What's New",
        description: "Klipfolio Klips' Latest Features",
        tracking: ["noindex", "nofollow", "noarchive"],
      }}
      fullWidth
      marginless  
      clean
    >
      <WhatsNew embed />
    </Layout>
  )
}

export default WhatsNewPage
