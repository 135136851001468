import React from "react"
import Flex from "@components/elements/flex"
import Html from "@components/html"
import { graphql, useStaticQuery } from "gatsby"
import Heading from "@components/elements/heading"
import Anchor from "@components/elements/anchor"
import Image from "@components/image"
import Div from "@components/elements/div"
import styled from "styled-components"
import Container from "@components/layout/container"
import { SideBar } from "@components/common/sidebar"
import PropTypes from "prop-types"
import media from "../../styles/media"
import { ImprovementIcon } from "@components/icons/whats-new/improvement"
import { NewFeatureIcon } from "@components/icons/whats-new/new-feature"
import { HighGradient } from "@components/common/gradient"
import { useGradient } from "@hooks/use-gradient"
import { UpdateIcon } from "@components/icons/whats-new/update"
import Paragraph from "@components/elements/paragraph"

const WhatsNewContainer = styled.div`
  display: grid;
  grid-template-columns: ${props => (props.embed ? "1fr" : "250px 1fr")};
  ${media.md`
    display: flex;
  `}
`

const DateDivider = styled(Heading)`
  display: flex;
  align-items: center;
  color: ${props => (props.embed ? "#999" : "inherit")};
  font-size: ${props => (props.embed ? "1rem" : "2rem")};
  font-weight: ${props => (props.embed ? 500 : 700)};

  ${props =>
    props.embed &&
    `
    &:before, &:after {
      content: '';
      height: 1px;
      background: #eee;
      flex: 1;
    }
    
    &:before {
      margin-right: 1rem;
    }
    
    &:after {
      margin-left: 1rem;
    }
  `}
`

const reduceMonths = data => {
  const uniqueDates = {}
  const uniqueMonths = data.reduce((months, current) => {
    const year = current.node.date.substring(current.node.date.length - 4)
    const month = current.node.date.substring(0, current.node.date.length - 5)

    if (!uniqueDates[year]) {
      uniqueDates[year] = []
    }

    if (!uniqueDates[year].includes(month)) {
      uniqueDates[year].push(month)
      months.push(current.node.id)
    }

    return months
  }, [])

  return uniqueMonths
}

const FEATURE_DEFAULTS = {
  fontSize: "0.8rem",
  fontWeight: 600,
  borderRadius: "0.5rem",
  flexFlow: "row",
  padding: "0.25rem 0.75rem",
  gap: "0.25rem;",
  alignItems: "center",
}

const FeatureType = ({ type }) => {
  switch (type) {
    case "improvement":
      return (
        <Flex background="#FFF5E0" color="#D24414" {...FEATURE_DEFAULTS}>
          <ImprovementIcon />
          <span>Improvement</span>
        </Flex>
      )
    case "new":
      return (
        <Flex background="#E8FDE2" color="#00805D" {...FEATURE_DEFAULTS}>
          <NewFeatureIcon />
          <span>New Feature</span>
        </Flex>
      )
    case "update":
      return (
        <Flex background="#EDF3FD" color="#343C8D" {...FEATURE_DEFAULTS}>
          <UpdateIcon />
          <span>Update</span>
        </Flex>
      )
  }
}

FeatureType.propTypes = {
  type: PropTypes.string.isRequired,
}

export const WhatsNew = ({ embed = false }) => {
  const { allWhatsNew } = useStaticQuery(graphql`
    query WhatsNewQuery {
      allWhatsNew(
        sort: { fields: date, order: DESC }
        filter: { status: { eq: "published" } }
      ) {
        edges {
          node {
            id
            title
            body
            date(formatString: "MMMM YYYY")
            link
            cover_video {
              publicUrl
            }
            cover_image {
              cdn
              placeholder
              title
            }
            type
          }
        }
      }
    }
  `)

  const newFeatures = allWhatsNew.edges
  const uniqueMonths = reduceMonths(allWhatsNew.edges)
  const { gradient } = useGradient(true)

  return (
    <Container relative fullWidth style={{ overflowX: "clip" }}>
      {!embed && <HighGradient src={gradient.cdn} />}
      <Div
        position="relative"
        container
        margin={embed ? "0 auto" : "10rem auto 6rem"}
        marginMd={embed ? "0 auto" : "8rem auto 4rem"}
      >
        {!embed && (
          <Div center margin="10rem 0 6rem" marginMd="8rem 0 4rem">
            <Heading fontSize="4rem" center margin="1rem 0 1rem">
              What&apos;s New
            </Heading>
            <Paragraph>
              Looking for PowerMetrics updates?&nbsp;
              <Anchor link="https://www.powermetrics.app/whats-new">
                Click here
              </Anchor>
            </Paragraph>
          </Div>
        )}
        <WhatsNewContainer embed={embed}>
          {!embed && <SideBar />}
          <Div>
            <Flex margin={embed ? "0 auto" : "0 auto 6rem"}>
              {newFeatures.map(({ node: feature }, i) => {
                return (
                  <React.Fragment key={`newFeature-${i}`}>
                    {uniqueMonths.includes(feature.id) ? (
                      <DateDivider as="h2" padding="0 0 2rem" embed={embed}>
                        {feature.date}
                      </DateDivider>
                    ) : null}
                    <Flex gap="1rem" margin={embed ? "0 0 2rem" : "0 0 4rem"}>
                      <Flex
                        gap="1rem"
                        margin="0 0 0.5rem"
                        flexFlow="row"
                        alignItems="center"
                      >
                        {feature.type && <FeatureType type={feature.type} />}
                        <Heading
                          fontWeight="700"
                          tabletScale={0.9}
                          mobileScale={0.8}
                          fontSize="1.6rem"
                          as="h3"
                        >
                          {feature.title}
                        </Heading>
                      </Flex>
                      <Flex borderRadius="1rem" overflow="hidden">
                        {feature.cover_image ? (
                          <Image file={feature.cover_image} />
                        ) : (
                          <video
                            style={{ width: "100%" }}
                            autoPlay
                            muted
                            loop
                            playsInline
                            src={feature.cover_video.publicUrl}
                          />
                        )}
                      </Flex>
                      <Flex gap="1rem" fontSize="1.1rem">
                        <Html parseElements inherit html={feature.body} />
                        {feature.link && (
                          <Anchor
                            target="_blank"
                            rel="noreferrer"
                            inherit
                            link={feature.link}
                          >
                            Learn more
                          </Anchor>
                        )}
                      </Flex>
                    </Flex>
                  </React.Fragment>
                )
              })}
            </Flex>
          </Div>
        </WhatsNewContainer>
      </Div>
    </Container>
  )
}

WhatsNew.propTypes = {
  embed: PropTypes.bool,
}
